@import "minima";

div#m img {
  max-width: none !important;
}

.page-content {
  padding: 10px 0;
}

.metamorfity {
  border-top: 25px solid hsla(40, 100%, 50%, 0.2);
}

.sedimenty {
  border-top: 25px solid hsla(120, 100%, 50%, 0.2);
}

.magmatity {
  border-top: 25px solid hsla(0, 100%, 50%, 0.2);
}

.site-nav {
  line-height: 2;
}

.post-list > li{
  margin: 0 5px 10px 0;
}

.grid-item a{
  position: relative;
  width: 350px;
  padding: 0;
  margin: 0;
}

.grid-item a span{
  position: absolute;
  bottom: 0;
  left: 0;
  color: #000;
  background: rgba(255,255,255,.7);
  padding: 5px 10px;
  font-size: 20px;
  width: calc(100% - 20px);
}

.grid-item a:hover span{
  background: rgba(255,255,255,.9);
  transition: all .5s;
}